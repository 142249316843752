
:root {
    --color-white: #ffffff;
    --color-dark-white: #e5e1e1;
    --color-black: #212227;
    --color-avium: #df4d8d;
    --color-cloak: #6b6362;
    --color-lunar: #888281;
    --color-fields: #20b70c;
    --color-rage: #f42b03;
    --color-apricot: #ebaf26;

    --color-skeleton: #e5e1e1;
    --color-skeleton-inner: #cecbcb;
}
