html,
body {
    background: var(--color-white);
    max-height: 100%;
}

body {
    padding-top: 70px;
}

.scroll-disabled {
    overflow: hidden;
    position: fixed;
    width: 100%;
}

main {
    padding-top: 15px;
    padding-bottom: 70px;
}

img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

button {
    background-color: unset;
    border: none;
    padding: 0;
}

a {
    text-decoration: none;
}

p,
h2,
figure {
    margin-bottom: 0;
}

i {
    font-style: normal;
}

.scale-button {
    @media screen and (min-width: 1400px) {
        transition: transform 0.2s;

        &:hover {
            transform: scale(0.96);
        }
    }
}

.flex-row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: -5px;
}

.back {
    display: flex;
    align-items: center;
    padding: 10px;
    color: var(--color-cloak);

    figure {
        margin-right: 10px;
    }
}

.save-button {
    position: sticky;
    bottom: 0;
    padding: 10px 0;
    background-color: var(--color-white);

    button {
        display: block;
        max-width: 400px;
        width: 100%;
        margin: 0 auto;
    }

    a {
        display: block;
        width: 100%;
        padding: 10px;
        background: var(--color-avium);
        border-radius: 30px;
        font-size: 1.5em;
        color: var(--color-white);
    }
}

.basic-input {
    width: 100%;
    margin: 8px 0;
    padding: 5px 10px;
    border: 3px solid var(--color-cloak);
    border-radius: 20px;
    outline: none;

    &::placeholder {
        color: #8f8f8f;
    }
}

.header-shadow {
    box-shadow: 0 -24px 46px 15px rgb(0 0 0 / 41%);
}

.image-carousel {
    display: flex;
    max-height: 300px;
    margin: 0 -4px;
    margin-bottom: 24px;
    overflow-x: scroll;

    img {
        flex: 1;
        object-fit: cover;
        margin: 0 4px;
        max-width: 100%;
        height: auto;
    }
}

iframe {
    width: 100%;
}

.message-content {
    p {
        margin-bottom: 8px;
    }

    a {
        color: var(--color-black);
        text-decoration: underline;
    }
}

.download-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 124px;
    height: 124px;
    margin: 16px auto;
    border-radius: 50%;
    border: 20px solid var(--color-cloak);
    background: var(--color-avium);
    text-decoration: none !important;

    &__first-section {
        text-decoration: underline;
        color: var(--color-white);
        text-align: center;
        font-size: 28px;
        line-height: 28px;
        margin-top: 4px;
    }

    &__second-section {
        text-align: center;
        color: var(--color-white);
        font-size: 12px;
    }

    @media screen and (min-width: 992px) {
        margin: 16px 0;
    }

    @media screen and (min-width: 1400px) {
        transition: transform 0.2s;

        &:hover {
            transform: scale(0.96);
        }
    }
}

.dynamic-html {
    p {
        margin-bottom: 8px;
    }

    ul, ol {
        margin-bottom: 8px;
    }
}

.table {
    min-width: 100%;

    &__container {
        margin-top: 16px;
        max-width: 100%;
        overflow-y: scroll;
    }

    &__head {
        background-color: var(--color-avium);
        color: var(--color-white);
        font-weight: bold;
    }

    &__column {
        flex: 1;
        padding: 8px 8px;
        line-height: 24px;
        white-space: nowrap;

        &--small {
            flex: unset;
            width: 24px;
            padding: 8px 4px;
            text-align: center;
        }
    }

    &__body {
        color: var(--color-black);
        border-top: none;
    }

    &__row {
        border-bottom: 1px solid var(--color-lunar);

        &:nth-child(odd) {
            background-color: var(--color-dark-white);
        }
    }

    &__button {
        border-radius: 50%;
        border: 1px solid var(--color-avium);
        width: 32px;
        height: 32px;
        margin-right: 8px;
    }
}
