@font-face {
    font-family: Insignia;
    src: url('/assets/fonts/insignia.woff');
    font-display: swap;
}

@font-face {
    font-family: SanFrancisco;
    src: url('/assets/fonts/san-francisco.woff');
    font-display: swap;
}

body * {
    font-family: Insignia;
}
